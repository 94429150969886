import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { $get, $convertNumberFormat } from "../utils/common";
import Swal from "sweetalert2";

function Home() {
  const navigate = useNavigate();
  const [activeSwiperIndex, setActiveSwiperIndex] = useState(0);

  const [quoteHistoryCountTotal, setQuoteHistoryCountTotal] = useState(0);

  const getQuoteHistoryCountTotal = async () => {
    const res = await $get("/api/quote-history-count-total");
    setQuoteHistoryCountTotal(res.data.count);
  };

  useEffect(() => {
    getQuoteHistoryCountTotal();
  }, []);

  return (
    <div>
      <section
        className="bg-dark bg-size-cover bg-repeat-0 bg-position-center position-relative overflow-hidden py-5 mb-4"
        // style={{
        //   backgroundImage: "url(assets/img/landing/saas-3/hero/hero-bg.jpg)",
        // }}
        data-bs-theme="dark"
      >
        <div className="container position-relative mt-5 pt-xl-5 pt-md-3 zindex-5">
          <div className="row justify-content-md-start justify-content-center flex-md-nowrap pt-lg-5 pt-4">
            <div className="col-xl-5 col-md-6 col-sm-10 d-flex flex-column justify-content-between mb-md-5 mb-md-4 mb-3 pb-xl-5 pb-lg-4">
              <h2 className="display-3 pb-2 pb-sm-3">
                내 인생에 한번 뿐인 건축
              </h2>
              <p className="text-body fs-lg d-md-none d-xl-block pb-2 pb-md-0 mb-4 mb-md-5">
                전국 최초로 제공되는 'AI 견적' 서비스를 통해 예비 건축주는 꿈에
                그리던 집에 대한 건축 예상 비용을 쉽게 확인할 수 있습니다. '100%
                건축주 직영 시스템'을 도입하여 건축 비용에 대한 투명하고 공정한
                거래를 보장합니다.
              </p>
              <div className="d-flex justify-content-center justify-content-md-start pb-2 pt-lg-2 pt-xl-0">
                <a
                  onClick={() => navigate("/ai-quote")}
                  className="btn btn-lg btn-primary shadow-primary me-3 me-sm-4"
                >
                  AI 견적 바로가기
                </a>
                <a
                  onClick={() => navigate("/contact")}
                  className="btn btn-lg btn-outline-secondary"
                >
                  문의하기
                </a>
              </div>
            </div>
            <div className="col-xl-7 col-md-6 d-md-flex justify-content-end">
              <div
                className="align-self-end ms-xl-0 ms-md-4 p-lg-4 p-sm-3 p-2 rounded-4 overflow-hidden"
                style={{
                  background:
                    "linear-gradient(153.32deg, rgba(255, 255, 255, .3) -65.62%, rgba(255, 255, 255, .1) 83.28%)",
                  boxShadow: "0 .25rem 1.5rem -.0625rem rgba(0, 0, 0, .2)",
                  backdropFilter: "blur(25px)",
                }}
              >
                <img
                  src="./assets/images/home-bg-01.png"
                  width={1122}
                  alt="Layer"
                  className="rounded-4"
                  style={{ boxShadow: "0 0 7.5rem rgba(0, 0, 0, .1)" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container my-5 pt-xl-5 pt-lg-4 pt-md-3 pb-lg-5 pb-md-4 pb-3">
        <h2 className="h1 mt-xl-3 mb-4 pb-3 text-center">
          인생주택만의 차별화되고 혁신적인 건축시스템
        </h2>
        <ul
          className="nav nav-tabs flex-nowrap justify-content-sm-center overflow-auto mb-lg-4 mb-3 pb-md-3 pb-2 text-nowrap"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <a
              href="#economic-analysis"
              className="nav-link active"
              data-bs-toggle="tab"
              role="tab"
              aria-selected="true"
            >
              <i className="bx bx-star opacity-60 me-2 fs-lg" />
              AI 견적 제공
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              href="#investments"
              className="nav-link"
              data-bs-toggle="tab"
              role="tab"
              aria-selected="false"
              tabIndex={-1}
            >
              <i className="bx bx-briefcase-alt-2 opacity-60 me-2 fs-lg" />
              건축주 직영 시스템
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              href="#corporate-finance"
              className="nav-link"
              data-bs-toggle="tab"
              role="tab"
              aria-selected="false"
              tabIndex={-1}
            >
              <i className="bx bx-bar-chart-alt-2 opacity-60 me-2 fs-lg" />
              투명한 건축 비용 관리
            </a>
          </li>
        </ul>
        <div className="tab-content mb-xl-3 py-4 bg-secondary rounded-3">
          <div
            className="tab-pane fade show active"
            id="economic-analysis"
            role="tabpanel"
          >
            <div className="row align-items-center pt-3 pt-sm-4 pt-md-0 px-3 px-sm-4 px-lg-0">
              <div className="col-lg-4 col-md-5 offset-lg-1 text-center text-md-start">
                <h3 className="h2 mb-lg-4 mb-3">AI 견적 제공</h3>
                <p className="mb-4 pb-lg-3">
                  오랜 관행으로 자리 잡아온 잘못된 평당 건축비 견적 방식과는
                  달리 'AI 견적'은 예비 건축주가 꿈꾸는 집에 대한 건축 예상
                  비용을 설계비, 인허가비, 시공비 등 건축 진행에 필요한 세부적인
                  비용을 비용 항목별로 쉽게 확인할 수 있습니다. 누구나 무료로 내
                  인생주택에 대한 건축 예상 비용을 확인할 수 있습니다.
                </p>
                <a
                  onClick={() => navigate("/ai-quote")}
                  className="btn btn-primary"
                >
                  AI 견적 바로가기
                </a>
              </div>
              <div className="col-lg-6 col-md-7 mt-4 pt-md-0 pt-2">
                {/* video play */}
                <video
                  src="./assets/images/ai-quote-demo-simple.mp4"
                  style={{ height: "400px" }}
                  autoPlay
                  loop
                  muted
                />
              </div>
            </div>
          </div>
          {/* Investments */}
          <div className="tab-pane fade" id="investments" role="tabpanel">
            <div className="row align-items-center pt-3 pt-sm-4 pt-md-0 px-3 px-sm-4 px-lg-0">
              <div className="col-lg-5 col-md-6 offset-lg-1 text-center text-md-start">
                <h3 className="h2 mb-lg-4 mb-3">건축주 직영 시스템</h3>
                <div className="d-sm-flex mb-4 pb-lg-3 text-sm-start">
                  <p className="mb-4 pb-lg-3">
                    건축주 직영 시스템이란 건축주가 중심이 되어 진행하는
                    투명하고 합리적인 비용으로 건축이 이루어지는 인생주택만의
                    노하우가 담긴 시스템입니다. 설계에서 시공까지 건축주 직영
                    시스템을 도입하여 모든 과정에 건축주가 직접 참여할 수
                    있습니다. 인생주택 건축 프로젝트 관리 시스템을 통해 건축주는
                    건축 각 과정에 대한 진행 상황을 실시간으로 확인할 수
                    있습니다. 시공과정에서는 매일 현장에서 진행된 작업에 대한
                    작업일지를 제공하여 건축주가 직접 현장을 확인할 수 있습니다.
                  </p>
                </div>
                {/* <a href="#" className="btn btn-primary">
                  Learn more
                </a> */}
              </div>
              <div className="col-lg-5 col-md-6 mt-4 pt-md-0 pt-2">
                <img
                  src="./assets/images/project-inbox.png"
                  className="d-block my-lg-2 mx-auto me-md-0 rounded-3"
                  width={595}
                  alt="Image"
                />
              </div>
            </div>
          </div>
          {/* Corporate Finance */}
          <div className="tab-pane fade" id="corporate-finance" role="tabpanel">
            <div className="row align-items-center pt-3 pt-sm-4 pt-md-0 px-3 px-sm-4 px-lg-0">
              <div className="col-lg-4 col-md-5 offset-lg-1 text-center text-md-start">
                <h3 className="h2 mb-lg-4 mb-3">투명한 건축 비용 관리</h3>
                <ul className="list-unstyled mb-4 pb-lg-3">
                  <li className="d-flex justify-content-md-start justify-content-center mt-2">
                    <i className="bx bx-check-circle me-2 fs-5 text-primary" />
                    건축에 투입된 기술자에 대한 인건비를 건축주가 직접 결제
                  </li>
                  <li className="d-flex justify-content-md-start justify-content-center mt-2">
                    <i className="bx bx-check-circle me-2 fs-5 text-primary" />
                    건축에 사용된 자재 목록을 상세하게 제공
                  </li>
                  <li className="d-flex justify-content-md-start justify-content-center mt-2">
                    <i className="bx bx-check-circle me-2 fs-5 text-primary" />
                    현장 소장이 진행되는 과정을 실시간으로 제공
                  </li>
                </ul>
                {/* <a href="#" className="btn btn-primary">
                  Learn more
                </a> */}
              </div>
              <div className="col-lg-6 col-md-7 mt-4 pt-md-0 pt-2">
                <img
                  src="./assets/images/project-cost.png"
                  className="d-block my-lg-2 mx-auto me-md-0 rounded-3"
                  width={595}
                  alt="Image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light">
        <div className="container text-center pb-5">
          <h2 className="h1 mb-4">"Build your life, live your dream!"</h2>
          <p className="lead pb-3 mb-3">
            {$convertNumberFormat(quoteHistoryCountTotal)} 건의 AI 견적이
            성공적으로 제공되어, 많은 고객들이 꿈의 집을 설계하는 데 도움을
            받았습니다.
          </p>
          <a
            onClick={() => navigate("/ai-quote")}
            className="btn btn-primary shadow-primary btn-lg mb-1"
          >
            AI 견적 바로가기
          </a>
        </div>
      </section>

      <section className="container mb-5 pb-lg-5 pb-md-4 pb-3">
        <div className="row gy-4 mb-xl-3">
          <div className="col-lg-6 d-flex flex-column order-2 order-lg-1">
            <h2 className="h1 mb-md-4 mb-3 py-lg-3 text-lg-start text-center">
              인생주택은 정직합니다.
            </h2>
            {/* Swiper slider */}
            <Swiper
              modules={[Pagination, Navigation, Autoplay]}
              // spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              // autoHeight={true}
              navigation={{
                prevEl: "#prev-industry",
                nextEl: "#next-industry",
              }}
              className="swiper mx-0"
              onSlideChange={(swiper) =>
                setActiveSwiperIndex(swiper.activeIndex)
              }
            >
              <SwiperSlide
                className="text-center text-lg-start"
                style={{ width: "100%" }}
              >
                <h3 className="h4 mb-4">
                  전문 건축 디자이너의 1:1 맞춤설계 제공
                  {/* <i className="bx bx-right-arrow-alt text-primary fs-3 fw-normal ms-2 mt-1" /> */}
                </h3>
                <p>
                  <b>인생주택은 고객에게 집중합니다.</b>
                </p>
                <p>
                  원하는 집의 구조, 원하는 디자인, 원하는 예산에 맞춰 최적의
                  설계를 제공합니다. 인생주택의 전문 디자이너가 옆에서 도와드려
                  1:1 맞춤형 설계를 제공합니다.
                </p>
              </SwiperSlide>
              <SwiperSlide
                className="text-center text-lg-start"
                style={{ width: "100%" }}
              >
                <h3 className="h4 mb-4">
                  높지 않은 공사비! 실 견적 제공!
                  {/* <i className="bx bx-right-arrow-alt text-primary fs-3 fw-normal ms-2 mt-1" /> */}
                </h3>
                <p>
                  <b>원하는 구조의 집은 건축비가 얼마나 들까요?</b>
                </p>
                <p>
                  그동안 시장의 잘못된 관행들로 높았던 건축비를 실 견적을 통해
                  예상 비용을 제공합니다. 인생주택은 예상 비용을 제공하는 것
                  뿐만 아니라 설계비, 인허가비, 시공비 등 건축 진행에 필요한
                  세부적인 비용을 비용 항목별로 쉽게 확인할 수 있습니다.
                </p>
              </SwiperSlide>
              <SwiperSlide
                className="text-center text-lg-start"
                style={{ width: "100%" }}
              >
                <h3 className="h4 mb-4">
                  건축과정에 실시간 참여
                  {/* <i className="bx bx-right-arrow-alt text-primary fs-3 fw-normal ms-2 mt-1" /> */}
                </h3>
                <p>
                  <b>집을 짓는 과정을 실시간으로 확인하세요!</b>
                </p>
                <p>
                  인생주택 건축 프로젝트 관리 시스템을 통해 건축주는 건축 각
                  과정에 대한 진행 상황을 실시간으로 확인할 수 있습니다.
                  시공과정에서는 매일 현장에서 진행된 작업에 대한 작업일지를
                  제공하여 건축주가 직접 현장을 확인할 수 있습니다.
                </p>
              </SwiperSlide>
            </Swiper>
            {/* Slider controls (Prev / next buttons) */}
            <div className="d-flex justify-content-center justify-content-lg-start mt-2 mt-lg-auto">
              <button
                type="button"
                id="prev-industry"
                className="btn btn-prev btn-icon btn-sm me-2"
                aria-label="Previous slide"
                disabled={activeSwiperIndex === 0}
              >
                <i className="bx bx-chevron-left" />
              </button>
              <button
                type="button"
                id="next-industry"
                className="btn btn-next btn-icon btn-sm ms-2"
                aria-label="Next slide"
                disabled={activeSwiperIndex === 2}
              >
                <i className="bx bx-chevron-right" />
              </button>
            </div>
          </div>
          <div className="col-lg-5 offset-xl-1 order-1 order-lg-2">
            {/* Swiper tabs (Industry images) */}
            <div className="swiper-tabs">
              {/* Industry 1 */}
              <div
                id="industry-1"
                className={`swiper-tab ${
                  activeSwiperIndex === 0 ? "active" : ""
                }`}
              >
                <img
                  src="./assets/images/gallery/gallery01.png"
                  className="rounded-3"
                  alt="Image"
                />
              </div>
              {/* Industry 2 */}
              <div
                id="industry-2"
                className={`swiper-tab ${
                  activeSwiperIndex === 1 ? "active" : ""
                }`}
              >
                <img
                  src="./assets/images/gallery/gallery02.png"
                  className="rounded-3"
                  alt="Image"
                />
              </div>
              {/* Industry 3 */}
              <div
                id="industry-3"
                className={`swiper-tab ${
                  activeSwiperIndex === 2 ? "active" : ""
                }`}
              >
                <img
                  src="./assets/images/gallery/gallery03.png"
                  className="rounded-3"
                  alt="Image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-secondary py-5">
        <div className="container text-center py-1 py-md-4 py-lg-5">
          <h2 className="h1 mb-4">정직한 주택 건축을 약속합니다.</h2>
          <p className="lead pb-3 mb-3">
            내인생에 한번뿐인 건축을 준비하는 예비 건축주님들에게 인생주택이
            든든한 동반자가 되겠습니다.
          </p>
          <a
            onClick={() => navigate("/contact")}
            className="btn btn-primary shadow-primary btn-lg mb-1"
          >
            건축 문의하기
          </a>
        </div>
      </section>
    </div>
  );
}

export default Home;
