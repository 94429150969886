import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function HouseQuoteModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [houseQuote, setHouseQuote] = useState(null);
  const [houseRoofList, setHouseRoofList] = useState([]);
  const [houseExteriorList, setHouseExteriorList] = useState([]);
  const [houseStyleList, setHouseStyleList] = useState([]);

  const doCreate = async () => {
    if (!houseQuote.house_style_id) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "건축 스타일을 선택하세요.",
      });
      return;
    }

    if (!houseQuote.house_exterior_id) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "외관 재질을 선택하세요.",
      });
      return;
    }

    if (!houseQuote.house_roof_id) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "지붕 재질을 선택하세요.",
      });
      return;
    }

    if (!houseQuote.house_floor) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "층수를 선택하세요.",
      });
      return;
    }
    // 정말 추가하시겠습니까?
    Swal.fire({
      title: "건축 AI 견적 옵션 추가",
      text: "정말 추가하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "추가",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $post("/api/masterdata/house-quote", houseQuote);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "건축 AI 견적 옵션이 추가되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "건축 AI 견적 옵션 추가에 실패했습니다.",
          });
        }
      }
    });
  };

  const doSave = async () => {
    if (!houseQuote.house_style_id) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "건축 스타일을 선택하세요.",
      });
      return;
    }

    if (!houseQuote.house_exterior_id) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "외관 재질을 선택하세요.",
      });
      return;
    }

    if (!houseQuote.house_roof_id) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "지붕 재질을 선택하세요.",
      });
      return;
    }

    if (!houseQuote.house_floor) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "층수를 선택하세요.",
      });
      return;
    }

    // 정말 수정하시겠습니까?
    Swal.fire({
      title: "건축 AI 견적 옵션 수정",
      text: "정말 수정하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(
          `/api/masterdata/house-quote/${houseQuote.house_quote_id}`,
          {
            house_style_id: houseQuote.house_style_id,
            house_exterior_id: houseQuote.house_exterior_id,
            house_roof_id: houseQuote.house_roof_id,
            house_floor: houseQuote.house_floor,
            active_yn: houseQuote.active_yn,
          }
        );
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "건축 AI 견적 옵션이 수정되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "건축 AI 견적 옵션 수정에 실패했습니다.",
          });
        }
      }
    });
  };

  const getHouseRoofList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-roof");
    if (res.status === 200) {
      setHouseRoofList(res.data);
      console.log(res.data);
    }

    setLoading(false);
  };

  const getHouseExteriorList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-exterior");
    if (res.status === 200) {
      setHouseExteriorList(res.data);
      console.log(res.data);
    }

    setLoading(false);
  };

  const getHouseSytleList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-style");
    if (res.status === 200) {
      setHouseStyleList(res.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getHouseSytleList();
    getHouseExteriorList();
    getHouseRoofList();

    if (props.houseQuote) {
      setHouseQuote(props.houseQuote);
    } else {
      setHouseQuote({
        house_style_id: "",
        house_exterior_id: "",
        house_roof_id: "",
        house_floor: 1,
        active_yn: "Y",
      });
    }
    btnRef.current.click();
  }, [props.houseQuote]);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#houseQuoteModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="houseQuoteModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">AI 견적 옵션</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            {houseQuote && (
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="houseSylte" className="form-label required">
                    건축 스타일
                  </label>
                  <select
                    className="form-select"
                    id="houseStyle"
                    value={houseQuote.house_style_id}
                    onChange={(e) => {
                      setHouseQuote({
                        ...houseQuote,
                        house_style_id: e.target.value,
                      });
                    }}
                  >
                    <option value="">선택</option>
                    {houseStyleList.map((item, index) => (
                      <option key={index} value={item.house_style_id}>
                        {item.house_style}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="houseExterior"
                    className="form-label required"
                  >
                    외관 재질
                  </label>
                  <select
                    className="form-select"
                    id="houseExterior"
                    value={houseQuote.house_exterior_id}
                    onChange={(e) => {
                      setHouseQuote({
                        ...houseQuote,
                        house_exterior_id: e.target.value,
                      });
                    }}
                  >
                    <option value="">선택</option>
                    {houseExteriorList.map((item, index) => (
                      <option key={index} value={item.house_exterior_id}>
                        {item.house_exterior}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="houseRoof" className="form-label required">
                    지붕 재질
                  </label>
                  <select
                    className="form-select"
                    id="houseRoof"
                    value={houseQuote.house_roof_id}
                    onChange={(e) => {
                      setHouseQuote({
                        ...houseQuote,
                        house_roof_id: e.target.value,
                      });
                    }}
                  >
                    <option value="">선택</option>
                    {houseRoofList.map((item, index) => (
                      <option key={index} value={item.house_roof_id}>
                        {item.house_roof}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="houseRoof" className="form-label required">
                    층수
                  </label>
                  <select
                    className="form-select"
                    id="houseRoof"
                    value={houseQuote.house_floor}
                    onChange={(e) => {
                      setHouseQuote({
                        ...houseQuote,
                        house_floor: e.target.value,
                      });
                    }}
                  >
                    <option value="">선택</option>
                    <option value="1">1층</option>
                    <option value="2">2층</option>
                    <option value="3">3층</option>
                  </select>
                </div>
                <div className="form-check form-switch">
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    활성
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    checked={houseQuote.active_yn === "Y" ? true : false}
                    onChange={(e) => {
                      setHouseQuote({
                        ...houseQuote,
                        active_yn: e.target.checked ? "Y" : "N",
                      });
                    }}
                  />
                </div>
              </div>
            )}

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
                onClick={() => {
                  props.onClose();
                }}
              >
                닫기
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  if (houseQuote && houseQuote.house_quote_id) {
                    doSave();
                  } else {
                    doCreate();
                  }
                }}
              >
                {houseQuote && houseQuote.house_quote_id ? "수정" : "추가"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HouseQuoteModal;
