import "./App.css";

import React, { useState } from "react";
import Home from "./pages/Home";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Project from "./pages/Project";
import ProjectInbox from "./pages/ProjectInbox";
import ProjectDashboard from "./pages/ProjectDashboard";
import ProjectCreate from "./pages/ProjectCreate";
import ProjectInfo from "./pages/ProjectInfo";
import ProjectDocument from "./pages/ProjectDocument";
import ProjectMaterial from "./pages/ProjectMaterial";
import ProjectWorker from "./pages/ProjectWorker";
import ProjectMessage from "./pages/ProjectMessage";
import ProjectDiary from "./pages/ProjectDiary";
import ProjectDiaryCreate from "./pages/ProjectDiaryCreate";
import ProjectDiaryView from "./pages/ProjectDiaryView";
import ProjectDiaryEdit from "./pages/ProjectDiaryEdit";
import ProjectCost from "./pages/ProjectCost";
import ProjectMember from "./pages/ProjectMember";
import ProjectPartner from "./pages/ProjectPartner";
import ProjectReport from "./pages/ProjectReport";
import ProjectAIBudget from "./pages/ProjectAIBudget";
import MaterialCategory from "./pages/MaterialCategory";
import MaterialUnit from "./pages/MaterialUnit";
import MaterialMaster from "./pages/MaterialMaster";
import MaterialPrice from "./pages/MaterialPrice";
import Material from "./pages/Material";
import Supplier from "./pages/Supplier";
import Partner from "./pages/Partner";
import Portfolio from "./pages/Portfolio";
import User from "./pages/User";
import Company from "./pages/Company";
import AIQuote from "./pages/AIQuote";
import QuoteHistory from "./pages/QuoteHistory";
import ExteriorType from "./pages/ExteriorType";
import HouseStyle from "./pages/HouseStyle";
import HouseType from "./pages/HouseType";
import HouseOption from "./pages/HouseOption";
import HouseSite from "./pages/HouseSite";
import HouseFunc from "./pages/HouseFunc";
import HouseExterior from "./pages/HouseExterior";
import HouseRoof from "./pages/HouseRoof";
import HouseQuote from "./pages/HouseQuote";
import ArchitectureProcess from "./pages/ArchitectureProcess";
import ConstrunctionProcess from "./pages/ConstructionProcess";
import PermitCategory from "./pages/PermitCategory";
import DocumentCategory from "./pages/DocumentCategory";
import DocumentType from "./pages/DocumentType";
import WorkerType from "./pages/WorkerType";
import Worker from "./pages/Worker";
import WorkerDetail from "./pages/WorkerDetail";
import Client from "./pages/Client";
import CostCategory from "./pages/CostCategory";
import CostSubcategory from "./pages/CostSubcategory";
import AIQuoteFaq from "./pages/AIQuoteFaq";
import Contact from "./pages/Contact";
import MyQuote from "./pages/MyQuote";
import MyProfile from "./pages/MyProfile";
import SystemInfo from "./pages/SystemInfo";
import AIQuoteStatistics from "./pages/AIQuoteStatistics";
import MaterialStatistics from "./pages/MaterialStatistics";
import Tooltip from "./component/Tooltip";
import Signup from "./pages/Signup";
// import Chat from "./pages/Chat";
// import FAQ from "./pages/FAQ";
// import FaqCategory from "./pages/FaqCategory";
// import FaqHistory from "./pages/FaqHistory";
// import Inquiry from "./pages/Inquiry";
// import InquiryAdmin from "./pages/InquiryAdmin";
// import Terms from "./pages/Terms";
// import Profile from "./pages/Profile";
// import UserList from "./pages/UserList";
// import TokenUsage from "./pages/TokenUsage";
// import Dashboard from "./pages/Dashboard";
import PageNotFound from "./pages/404";
// import NotAuthorized from "./pages/401";
// import InternalServerError from "./pages/500";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthProvider from "./login/AuthProvider";

function App() {
  const [triggerLogin, setTriggerLogin] = useState(false);

  const handleTriggerLogin = () => {
    console.log("handleTriggerLogin");
    setTriggerLogin(true);
  };

  return (
    <BrowserRouter>
      <AuthProvider>
        <main className="page-wrapper">
          <Tooltip />
          <Header
            triggerLogin={triggerLogin}
            setTriggerLogin={setTriggerLogin}
          />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/project" element={<Project />} />
            <Route path="/project-create" element={<ProjectCreate />} />
            <Route
              path="/project-inbox/:projectId"
              element={<ProjectInbox />}
            />
            <Route
              path="/project-dashboard/:projectId"
              element={<ProjectDashboard />}
            />
            <Route path="/project-info/:projectId" element={<ProjectInfo />} />
            <Route
              path="/project-document/:projectId"
              element={<ProjectDocument />}
            />
            <Route
              path="/project-material/:projectId"
              element={<ProjectMaterial />}
            />
            <Route path="/project-cost/:projectId" element={<ProjectCost />} />
            <Route
              path="/project-worker/:projectId"
              element={<ProjectWorker />}
            />
            <Route
              path="/project-message/:projectId"
              element={<ProjectMessage />}
            />
            <Route
              path="/project-diary/:projectId"
              element={<ProjectDiary />}
            />
            <Route
              path="/project-diary-create"
              element={<ProjectDiaryCreate />}
            />
            <Route
              path="/project-diary-view/:projectId/:projectDiaryId"
              element={<ProjectDiaryView />}
            />
            <Route path="/project-diary-edit" element={<ProjectDiaryEdit />} />
            <Route
              path="/project-member/:projectId"
              element={<ProjectMember />}
            />
            <Route
              path="/project-partner/:projectId"
              element={<ProjectPartner />}
            />
            <Route
              path="/project-report/:projectId"
              element={<ProjectReport />}
            />
            <Route
              path="/project-ai-budget/:projectId"
              element={<ProjectAIBudget />}
            />
            <Route path="/material-price" element={<MaterialPrice />} />
            <Route path="/resource-material" element={<Material />} />
            <Route path="/material-supplier" element={<Supplier />} />
            <Route path="/resource-partner" element={<Partner />} />
            <Route path="/ai-quote" element={<AIQuote />} />
            <Route path="/report-quote-history" element={<QuoteHistory />} />
            <Route path="/master-house-style" element={<HouseStyle />} />
            <Route path="/master-house-type" element={<HouseType />} />
            <Route path="/master-house-option" element={<HouseOption />} />
            <Route path="/master-house-site" element={<HouseSite />} />
            <Route path="/master-house-func" element={<HouseFunc />} />
            <Route path="/master-house-exterior" element={<HouseExterior />} />
            <Route path="/master-exterior-type" element={<ExteriorType />} />
            <Route path="/master-house-roof" element={<HouseRoof />} />
            <Route path="/master-house-quote" element={<HouseQuote />} />
            <Route
              path="/master-material-category"
              element={<MaterialCategory />}
            />
            <Route path="/master-material-unit" element={<MaterialUnit />} />
            <Route
              path="/master-material-master"
              element={<MaterialMaster />}
            />
            <Route
              path="/master-architecture-process"
              element={<ArchitectureProcess />}
            />
            <Route
              path="/master-construction-process"
              element={<ConstrunctionProcess />}
            />
            <Route
              path="/master-permit-category"
              element={<PermitCategory />}
            />
            <Route
              path="/master-document-category"
              element={<DocumentCategory />}
            />
            <Route path="/master-document-type" element={<DocumentType />} />
            <Route path="/master-worker-type" element={<WorkerType />} />
            <Route path="/master-cost-category" element={<CostCategory />} />
            <Route
              path="/master-cost-subcategory"
              element={<CostSubcategory />}
            />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/system-user" element={<User />} />
            <Route path="/system-company" element={<Company />} />
            <Route path="/resource-worker" element={<Worker />} />
            <Route path="/resource-worker-detail" element={<WorkerDetail />} />
            <Route path="/my-quote" element={<MyQuote />} />
            <Route path="/my-profile" element={<MyProfile />} />
            <Route path="/client" element={<Client />} />
            <Route path="/404" element={<PageNotFound />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/system-info" element={<SystemInfo />} />
            <Route
              path="/report-quote-statistics"
              element={<AIQuoteStatistics />}
            />
            <Route
              path="/report-material-statistics"
              element={<MaterialStatistics />}
            />
            <Route
              path="/signup"
              element={<Signup onLoginClick={handleTriggerLogin} />}
            />
          </Routes>
          <Footer />
        </main>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
