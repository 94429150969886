import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put, $upload2 } from "../utils/common";
import Swal from "sweetalert2";

function SignatureModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const startDrawing = (e) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.beginPath();
    ctx.moveTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    setIsDrawing(true);
  };

  const draw = (e) => {
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    ctx.lineTo(x, y);
    ctx.stroke();
  };

  const endDrawing = () => {
    setIsDrawing(false);
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const saveSignature = () => {
    Swal.fire({
      title: "프로젝트 최종 승인하시겠습니까?",
      text: "프로젝트 최종 승인 후 수정이 불가능합니다.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "최종 승인",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const canvas = canvasRef.current;
        const dataUrl = canvas.toDataURL("image/png");
        const blob = await (await fetch(dataUrl)).blob();
        const formData = new FormData();
        formData.append("signature", blob, "signature.png");

        const res = await $upload2(
          `/api/project/confirm/${props.projectId}`,
          formData
        );
        if (res.status === 200) {
          Swal.fire({
            title: "프로젝트 최종 승인",
            text: "프로젝트가 최종 승인되었습니다.",
            icon: "success",
          });
          props.onClose();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            title: "프로젝트 최종 승인 실패",
            text: "프로젝트 최종 승인 실패",
            icon: "error",
          });
        }
      }
    });

    // const canvas = canvasRef.current;
    // const dataUrl = canvas.toDataURL("image/png");
    // const link = document.createElement("a");
    // link.download = "signature.png";
    // link.href = dataUrl;
    // link.click();
  };

  useEffect(() => {
    btnRef.current.click();
  }, [props.projectId]);

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#signatureModal"
        ref={btnRef}
      ></button>
      <div
        className="modal fade"
        role="dialog"
        id="signatureModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">프로젝트 최종 승인 고객 서명</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={btnCloseRef}
                onClick={() => {
                  props.onClose();
                }}
              ></button>
            </div>
            <div className="modal-body">
              {/* canvas를 이용해서 서명을 받을 수 있도록 구현 */}
              <canvas
                ref={canvasRef}
                style={{ width: "100%", height: "250px" }}
                className="border border-gray-300"
                onMouseDown={startDrawing}
                onMouseMove={draw}
                onMouseUp={endDrawing}
                onMouseLeave={endDrawing}
                onTouchStart={startDrawing}
                onTouchMove={draw}
                onTouchEnd={endDrawing}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => {
                  clearCanvas();
                }}
              >
                초기화
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  saveSignature();
                }}
              >
                서명 완료
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignatureModal;
