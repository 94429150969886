import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $upload,
  $dateTimezone,
  $convertMarkdownToHtml,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import PlaceHoderCard from "../component/PlacehoderCard";
import MasterArchitectureSidebar from "../component/MasterArchitectureSidebar";
import HouseQuoteModal from "../component/HouseQuoteModal";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

function HouseQuote() {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const { isLoggedIn, logout, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [index, setIndex] = useState(-1);
  const [slides, setSlides] = useState([]);
  const [activeHouseQuote, setActiveHouseQuote] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const imageFileRef = useRef(null);

  const uploadImage = async (file) => {
    const res = await $upload("/api/masterdata/house-quote/image", file, {
      house_quote_id: activeHouseQuote.house_quote_id,
    });
    if (res.status === 200) {
      getList();
      Swal.fire({
        icon: "success",
        title: "성공",
        text: "이미지가 업로드되었습니다.",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "이미지 업로드에 실패했습니다.",
      });
    }
  };

  const deleteHouseQuote = async (houseQuoteId) => {
    Swal.fire({
      title: "외관 재질 삭제",
      text: "정말 삭제하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(
          `/api/masterdata/house-quote/${houseQuoteId}`
        );
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "외관 재질이 삭제되었습니다.",
          });
          getList();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "외관 재질 삭제에 실패했습니다.",
          });
        }
      }
    });
  };

  const getList = async () => {
    setLoading(true);
    const res = await $get("/api/masterdata/house-quote");
    if (res.status === 200) {
      setList(res.data);
      setSlides(
        res.data.map((hq) => ({
          src: hq.img_src
            ? `${serverUrl}/static/images/${hq.img_src.split("/").pop()}`
            : "assets/images/bg/house_blank2.png",
          title: `${hq.house_style}, ${hq.house_exterior}, ${hq.house_roof}`,
          id: hq.house_quote_id,
          width: hq.img_width,
          height: hq.img_height,
          srcSet: breakpoints.map((bp) => ({
            src: hq.img_src
              ? `${serverUrl}/static/images/${hq.img_src.split("/").pop()}`
              : "assets/images/bg/house_blank2.png",
            width: bp,
            height: Math.round((hq.img_height / hq.img_width) * bp),
          })),
        }))
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    if (userData && userData.role_level !== "system_admin") {
      navigate("/");
    }
  }, [userData]);

  // useEffect(() => {
  //   getFaqList();
  // }, [userData]);

  return (
    <section className="container pt-3">
      <div className="row">
        {/* Sidebar (User info + Account menu) */}
        <MasterArchitectureSidebar />
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="h2 pt-xl-1 pb-3">AI 견적 옵션 관리</h1>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setActiveHouseQuote(null);
                  setShowModal(true);
                }}
              >
                <i className="fa-solid fa-plus me-2"></i>
                추가
              </button>
            </div>
            <input
              type="file"
              ref={imageFileRef}
              style={{ display: "none" }}
              accept=".jpg,.jpeg,.png,.gif"
              onChange={(e) => {
                uploadImage(e.target.files[0]);
              }}
            />
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>사진</th>
                    <th>옵션</th>
                    {/* <th>등록일</th> */}
                    <th>활성</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          src={
                            item.img_src
                              ? `${serverUrl}/static/images/${item.img_src
                                  .split("/")
                                  .pop()}`
                              : "assets/images/bg/house_blank2.png"
                          }
                          className="d-block"
                          style={{ width: "50px", height: "50px" }}
                          onClick={() => {
                            setIndex(index);
                          }}
                        />
                      </td>
                      <td>{`${item.house_style}, ${item.house_exterior}, ${item.house_roof}, ${item.house_floor}층`}</td>
                      {/* <td>{$dateTimezone(item.create_datetime)}</td> */}
                      <td>{item.active_yn === "Y" ? "활성" : "비활성"}</td>
                      <td>
                        <button
                          className="btn btn-primary btn-sm me-2"
                          data-tooltip-id="btn-edit-tooltip"
                          onClick={() => {
                            setActiveHouseQuote(item);
                            setShowModal(true);
                          }}
                        >
                          <i className="fa-solid fa-pen"></i>
                        </button>
                        <button
                          className="btn btn-info btn-sm me-2"
                          data-tooltip-id="btn-photo-tooltip"
                          onClick={() => {
                            setActiveHouseQuote(item);
                            imageFileRef.current.click();
                          }}
                        >
                          <i className="fa-regular fa-image"></i>
                        </button>
                        <button
                          className="btn btn-danger btn-sm"
                          data-tooltip-id="btn-delete-tooltip"
                          onClick={() => {
                            deleteHouseQuote(item.house_quote_id);
                          }}
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {slides.length > 0 && (
        <Lightbox
          index={index}
          slides={slides}
          open={index >= 0}
          plugins={[Fullscreen, Slideshow, Captions, Video, Zoom, Thumbnails]}
          close={() => setIndex(-1)}
        />
      )}

      {showModal && (
        <HouseQuoteModal
          houseQuote={activeHouseQuote}
          onClose={() => {
            setShowModal(false);
          }}
          onList={() => {
            getList();
            setActiveHouseQuote(null);
            setShowModal(false);
          }}
        />
      )}
    </section>
  );
}

export default HouseQuote;
