import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  $get,
  $post,
  $delete,
  $put,
  $dateTimezone,
  $convertMarkdownToHtml,
  $convertNumberFormat,
  $toDate,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ProjectSidebar from "../component/ProjectSidebar";
import Chart from "react-apexcharts";
import SignatureModal from "../component/SignatureModal";

function ProjectReport() {
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { projectId } = useParams();
  const { isLoggedIn, logout, userData, checkProjectRole, getProjectRole } =
    useAuth();
  const [theme, setTheme] = useState("light");
  const [loading, setLoading] = useState(false);
  const [showMain, setShowMain] = useState(true);
  const [project, setProject] = useState(null);
  const [scheduleData, setScheduleData] = useState(null);
  const [projectCostList, setProjectCostList] = useState([]);
  const [projectBudget, setProjectBudget] = useState([]);
  const [projectWorkerList, setProjectWorkerList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);
  const [projectMemberList, setProjectMemberList] = useState([]);
  const [projectArchitectureProcessList, setProjectArchitectureProcessList] =
    useState([]);
  const [projectRole, setProjectRole] = useState(null);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const getScheduleData = async () => {
    setScheduleData({
      series: [
        {
          data: [
            {
              x: "건축기획단계",
              y: [
                new Date("2024-02-27").getTime(),
                new Date("2024-03-04").getTime(),
              ],
              fillColor: "#008FFB",
            },
            {
              x: "인허가단계",
              y: [
                new Date("2024-03-04").getTime(),
                new Date("2024-04-08").getTime(),
              ],
              fillColor: "#00E396",
            },
            {
              x: "착공준비단계",
              y: [
                new Date("2024-03-27").getTime(),
                new Date("2024-04-16").getTime(),
              ],
              fillColor: "#775DD0",
            },
            {
              x: "시공준비단계",
              y: [
                new Date("2024-04-16").getTime(),
                new Date("2024-05-12").getTime(),
              ],
              fillColor: "#FEB019",
            },
            {
              x: "시공단계",
              y: [
                new Date("2024-05-12").getTime(),
                new Date("2024-07-12").getTime(),
              ],
              fillColor: "#FEB019",
            },
            {
              x: "준공 및 인도",
              y: [
                new Date("2024-07-12").getTime(),
                new Date("2024-07-20").getTime(),
              ],
              fillColor: "#FF4560",
            },
          ],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "rangeBar",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
            borderRadius: 10,
            dataLabels: {
              hideOverflowingLabels: false,
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            var label = opts.w.globals.labels[opts.dataPointIndex];
            // console.log("val", val);
            // 종료일 - 시작일
            var days =
              Math.floor((val[1] - val[0]) / (1000 * 60 * 60 * 24)) + 1;

            // console.log("days", days);

            return days + "일";
            // return label + ": " + days + "일";
          },
          style: {
            colors: ["#f3f4f5", "#fff"],
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [50, 0, 100, 100],
          },
        },
        xaxis: {
          type: "datetime",
        },
        // yaxis: {
        //   show: false,
        // },
        grid: {
          row: {
            colors: ["#f3f4f5", "#fff"],
            opacity: 1,
          },
        },
      },
    });
  };

  const getProjectMember = async () => {
    try {
      const res = await $get(`/api/project/member/${projectId}`);
      setProjectMemberList(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getProjectPartner = async () => {
    try {
      const res = await $get(`/api/project/partner/${projectId}`);
      setPartnerList(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getProjectWorker = async () => {
    try {
      const res = await $get(`/api/diary/worker-sum/${projectId}`);
      setProjectWorkerList(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getProjectCost = async () => {
    try {
      const res = await $get(`/api/cost/project/category-sum/${projectId}`);
      setProjectCostList(res.data);
      return res;
    } catch (error) {
      console.error(error);
    }
  };

  const getProjectBudget = async () => {
    const res = await $get(`/api/project/budget/${projectId}`);
    if (res.status === 200) {
      const res2 = await getProjectCost();
      if (res2.status === 200) {
        const tempProjectCostList = res2.data;
        const tempProjectBudget = res.data.map((item) => {
          const tempCostCategory = tempProjectCostList.find(
            (cost) => cost.cost_category_id === item.cost_category_id
          );
          return {
            ...item,
            real_cost: tempCostCategory ? tempCostCategory.total_amount : 0,
          };
        });
        setProjectBudget(tempProjectBudget);
      }
    }
  };

  const getRandomColor = () => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  };

  const getProjectArchitectureProcess = async () => {
    const res = await $get(`/api/project/architecture-process/${projectId}`);
    if (res.status === 200) {
      setProjectArchitectureProcessList(res.data);

      const seriesData = [];
      res.data.map((process) => {
        // process.start_date, process.end_date이 null 이거나 빈문자열이면 패스
        if (!process.start_date || !process.end_date) {
          return;
        }
        seriesData.push({
          x: process.architecture_process_name,
          y: [
            new Date($toDate(process.start_date)).getTime(),
            new Date($toDate(process.end_date)).getTime(),
          ],
          fillColor: getRandomColor(),
        });
      });

      setScheduleData({
        series: [
          {
            data: seriesData,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "rangeBar",
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: true,
              borderRadius: 10,
              dataLabels: {
                hideOverflowingLabels: false,
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              var label = opts.w.globals.labels[opts.dataPointIndex];
              // console.log("val", val);
              // 종료일 - 시작일
              var days =
                Math.floor((val[1] - val[0]) / (1000 * 60 * 60 * 24)) + 1;

              // console.log("days", days);

              return days + "일";
              // return label + ": " + days + "일";
            },
            style: {
              colors: ["#f3f4f5", "#fff"],
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "light",
              type: "vertical",
              shadeIntensity: 0.25,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [50, 0, 100, 100],
            },
          },
          xaxis: {
            type: "datetime",
          },
          // yaxis: {
          //   show: false,
          // },
          grid: {
            row: {
              colors: ["#f3f4f5", "#fff"],
              opacity: 1,
            },
          },
        },
      });
    }
  };

  const confirmProject = async () => {
    // 프로젝트 최종 승인 하시겠습니까?
    Swal.fire({
      title: "프로젝트 최종 승인하시겠습니까?",
      text: "프로젝트 최종 승인 후 수정이 불가능합니다.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "최종 승인",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(`/api/project/confirm/${projectId}`);
        if (res.status === 200) {
          Swal.fire({
            title: "프로젝트 최종 승인",
            text: "프로젝트가 최종 승인되었습니다.",
            icon: "success",
          });
          getProjectDetail();
        } else {
          Swal.fire({
            title: "프로젝트 최종 승인 실패",
            text: "프로젝트 최종 승인 실패",
            icon: "error",
          });
        }
      }
    });
  };

  const getProjectDetail = async () => {
    const res = await $get(`/api/project/${projectId}`);
    if (res.status === 200) {
      setProject(res.data);
    }
  };

  useEffect(() => {
    if (!projectId) {
      navigate("/project");
    }

    checkProjectRole(projectId).then((res) => {
      if (!res) {
        navigate("/project");
      } else {
        getProjectRole(projectId).then((res) => {
          setProjectRole(res);
        });
        getProjectDetail();
        // getScheduleData();
        // getProjectCost();
        getProjectBudget();
        getProjectWorker();
        getProjectPartner();
        getProjectMember();
        getProjectArchitectureProcess();
      }
    });
  }, [projectId]);

  // useEffect(() => {
  //   getFaqList();
  // }, [userData]);

  return (
    <section className="container pt-3">
      <div className="row">
        {/* Sidebar (User info + Account menu) */}
        <ProjectSidebar projectId={projectId} />
        {/* Account details */}
        <div className="col-lg-9 col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          {project && (
            <div className="row">
              <div className="col-lg-12">
                <div className="card mt-4">
                  <div className="card-body">
                    <h3 className="h5">프로젝트 상세</h3>
                    <div className="row">
                      <div className="col-12">
                        <label className="form-label">프로젝트 상태</label>
                        <p>{project.project_status}</p>
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label">대지주소</label>
                        <p>{project.project_address}</p>
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label">건축면적</label>
                        <p>{project.house_size}m²</p>
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label">연면적</label>
                        <p>{project.total_floor_area}m²</p>
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label">건폐율</label>
                        <p>{project.building_to_land_ratio}%</p>
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label">용적률</label>
                        <p>{project.floor_area_ratio}%</p>
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label">건축스타일</label>
                        <p>{project.house_style}</p>
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label">외관재질</label>
                        <p>{project.house_exterior}</p>
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label">지붕재</label>
                        <p>{project.house_roof}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <h2 className="h5 pt-1 pt-lg-3 my-4">프로젝트 일정</h2> */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-4">
                <div className="card-body">
                  <h3 className="h5">프로젝트 진행 결과</h3>

                  {scheduleData != null && scheduleData.series.length > 0 && (
                    <Chart
                      options={scheduleData.options}
                      series={scheduleData.series}
                      type="rangeBar"
                      height={350}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card mt-2">
                <div className="card-body">
                  <h3 className="h5">비용 집계</h3>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>카테고리</th>
                          <th>계획(원)</th>
                          <th>실제 비용(원)</th>
                          <th>계획 대비 비용 차이(원)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projectBudget.map((cost, index) => (
                          <tr key={index}>
                            <td>{cost.cost_category_name}</td>
                            <td>{$convertNumberFormat(cost.planned_budget)}</td>
                            <td>{$convertNumberFormat(cost.real_cost)}</td>
                            <td>
                              {$convertNumberFormat(
                                cost.planned_budget - cost.real_cost
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr className="fw-bold">
                          <td>합계</td>
                          <td>
                            {$convertNumberFormat(
                              projectBudget.reduce(
                                (acc, cur) => acc + cur.planned_budget,
                                0
                              )
                            )}
                          </td>
                          <td>
                            {$convertNumberFormat(
                              projectBudget.reduce(
                                (acc, cur) => acc + cur.real_cost,
                                0
                              )
                            )}
                          </td>
                          <td>
                            {$convertNumberFormat(
                              projectBudget.reduce(
                                (acc, cur) =>
                                  acc + (cur.planned_budget - cur.real_cost),
                                0
                              )
                            )}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card mt-2">
                <div className="card-body">
                  <h3 className="h5">투입 인력 집계</h3>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>이름</th>
                          <th>총근무일수</th>
                          <th>총근무시간</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projectWorkerList.map((worker, index) => (
                          <tr key={index}>
                            <td>{worker.worker_name}</td>
                            <td>{$convertNumberFormat(worker.work_days)}</td>
                            <td>{$convertNumberFormat(worker.work_hours)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card mt-2">
                <div className="card-body">
                  <h3 className="h5">협력업체</h3>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>업체명</th>
                          <th>전문분야</th>
                          <th>전화번호</th>
                        </tr>
                      </thead>
                      <tbody>
                        {partnerList.map((partner, index) => (
                          <tr key={index}>
                            <td>{partner.partner_name}</td>
                            <td>{partner.partner_speciality}</td>
                            <td>{partner.phone_number}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* project_status가 준공완료 이고, projectRole이 client이면 최종 승인 버튼 보이기 */}
          {project && project.project_status === "준공완료" && (
            <div className="d-flex justify-content-center mt-4">
              {/*  */}
              <button
                className="btn btn-danger btn-lg"
                onClick={() => {
                  setShowSignatureModal(true);
                }}
              >
                최종 승인
              </button>
            </div>
          )}
          {project &&
            (projectRole === "client" ||
              userData.role_level === "system_admin") &&
            project.project_status === "최종승인" && (
              <div className="card mt-2">
                <div className="card-body">
                  <h3 className="h5">고객 최종 승인 서명</h3>
                  <img
                    src={`${serverUrl}/static/images/${project.signature_file_path
                      .split("/")
                      .pop()}`}
                    alt="최종 승인 서명"
                    style={{ width: "200px", height: "auto" }}
                  />
                </div>
              </div>
            )}
        </div>
      </div>
      {showSignatureModal && (
        <SignatureModal
          projectId={projectId}
          onClose={() => {
            setShowSignatureModal(false);
            getProjectDetail();
          }}
        />
      )}
    </section>
  );
}

export default ProjectReport;
