import React, { useState, useEffect, useRef } from "react";
import { $get, $post, $put } from "../utils/common";
import Swal from "sweetalert2";

function CostSubcategoryModal(props) {
  const btnRef = useRef(null);
  const btnCloseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [costSubcategory, setCostSubcategory] = useState(null);

  const doCreate = async () => {
    if (!costSubcategory.cost_subcategory_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "비용 항목 분류를 입력하세요.",
      });
      return;
    }

    // 정말 추가하시겠습니까?
    Swal.fire({
      title: "비용 항목 분류 추가",
      text: "정말 추가하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "추가",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $post("/api/masterdata/cost-subcategory", {
          cost_category_id: props.costCategory.cost_category_id,
          cost_subcategory_name: costSubcategory.cost_subcategory_name,
        });
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "비용 항목 분류가 추가되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "비용 항목 분류 추가에 실패했습니다.",
          });
        }
      }
    });
  };

  const doSave = async () => {
    if (!costSubcategory.cost_subcategory_name) {
      Swal.fire({
        icon: "error",
        title: "실패",
        text: "비용 항목 분류를 입력하세요.",
      });
      return;
    }

    // 정말 수정하시겠습니까?
    Swal.fire({
      title: "비용 항목 분류 수정",
      text: "정말 수정하시겠습니까?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "수정",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(
          `/api/masterdata/cost-subcategory/${costSubcategory.cost_subcategory_id}`,
          {
            cost_subcategory_name: costSubcategory.cost_subcategory_name,
          }
        );
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "성공",
            text: "비용 항목 분류가 수정되었습니다.",
          });
          props.onList();
          btnCloseRef.current.click();
        } else {
          Swal.fire({
            icon: "error",
            title: "실패",
            text: "비용 항목 분류 수정에 실패했습니다.",
          });
        }
      }
    });
  };

  useEffect(() => {
    if (!props.costCategory) {
      return;
    }

    if (props.costSubcategory) {
      setCostSubcategory(props.costSubcategory);
    } else {
      setCostSubcategory({
        cost_subcategory_name: "",
      });
    }
    btnRef.current.click();
  }, [props.costCategory, props.costSubcategory]);
  return (
    props.costCategory && (
      <div>
        <button
          type="button"
          className="btn btn-primary"
          style={{ display: "none" }}
          data-bs-toggle="modal"
          data-bs-target="#costSubcategoryModal"
          ref={btnRef}
        ></button>
        <div
          className="modal fade"
          role="dialog"
          id="costSubcategoryModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  "{props.costCategory.cost_category_name}"의 하위 비용 항목
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={btnCloseRef}
                  onClick={() => {
                    props.onClose();
                  }}
                ></button>
              </div>
              <div className="modal-body">
                {costSubcategory && (
                  <div className="mb-3">
                    <label htmlFor="costSubcategory" className="form-label">
                      비용 항목 분류
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="costSubcategory"
                      value={costSubcategory.cost_subcategory_name}
                      onChange={(e) => {
                        setCostSubcategory({
                          ...costSubcategory,
                          cost_subcategory_name: e.target.value,
                        });
                      }}
                      placeholder="비용 항목 분류를 입력하세요."
                    />
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    props.onClose();
                  }}
                >
                  닫기
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    if (
                      costSubcategory &&
                      costSubcategory.cost_subcategory_id
                    ) {
                      doSave();
                    } else {
                      doCreate();
                    }
                  }}
                >
                  {costSubcategory && costSubcategory.cost_subcategory_id
                    ? "수정"
                    : "추가"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default CostSubcategoryModal;
